const STAGING = "prod"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "zwzasj9gdq7nx31j6ba2apb0hu9ujd2s80pi4rzn83gb1taq",
  dev: "vimadnpop74f3zlrv2tmxv2ihh3wblsg6s7pzqc6hpndedjh",
  prod: "c3pteqjr0ysrs69e7eak38q3wvw5zzyzzn56y257abfteb8r",
  preview: "lghgym85xf9612idq509udk726gbe3dtvbjpxk3ibxdc0skx",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}