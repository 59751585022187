export const fontStyles = `
  Andale Mono=andale mono,monospace;
  Arial=arial,helvetica,sans-serif;
  Arial Black=arial black,sans-serif;
  Book Antiqua=book antiqua,palatino,serif;
  Comic Sans MS=comic sans ms,sans-serif;
  Courier New=courier new,courier,monospace;
  Georgia=georgia,palatino,serif;
  Helvetica=helvetica,arial,sans-serif;
  Impact=impact,sans-serif;
  Symbol=symbol;
  Tahoma=tahoma,arial,helvetica,sans-serif;
  Terminal=terminal,monaco,monospace;
  Times New Roman=times new roman,times,serif;
  Trebuchet MS=trebuchet ms,geneva,sans-serif;
  Verdana=verdana,geneva,sans-serif;
  Webdings=webdings;
  Wingdings=wingdings,zapf dingbats;
  Adobe Garamond Pro=AGaramond-Regular;`;

export const getFontStyles = (replaceFont = null, replacement = null) => {
    if (replaceFont && replacement) {
      return fontStyles.replace(new RegExp(replaceFont, 'g'), replacement);
    }
    return fontStyles;
};